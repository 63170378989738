import axios from 'axios';

const NAMESPACE = '/api/v1';

export async function getJobComments(token, jobId) {
    const config = {
        headers: { Authorization: `Bearer ${token}` },
    };
    const response = await axios.get(
        `${NAMESPACE}/jobComments/${jobId}`,
        config
    );
    return response;
}

export async function addComment(token, comment) {
    const config = {
        headers: { Authorization: `Bearer ${token}` },
    };
    const response = await axios.post(
        NAMESPACE + '/jobComments/',
        comment,
        config
    );
    return response;
}

export async function deleteJobComment(token, id) {
    const config = {
        headers: { Authorization: `Bearer ${token}` },
    };

    const response = await axios.delete(
        `${NAMESPACE}/jobComments/${id}`,
        config
    );
    return response;
}

export async function updateJobComment(token, id, note) {
    const config = {
        headers: { Authorization: `Bearer ${token}` },
    };

    const response = await axios.patch(
        `${NAMESPACE}/jobComments/${id}`,
        { note: note },
        config
    );
    return response;
}
