// 01/10/2023
import React from 'react';
import { ListItem, Box, Avatar } from '@mui/material';

import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import formatDate from '../../../utils/dateFormatter';

const JobListItem = (props) => {
    const priorityColors = ['#048282', '#eba434', '#941515'];
    const styles = {
        avatarContainer: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
        },
        avatar: {
            width: 24,
            height: 24,
            mb: 0.5,
            mr: 0.5,
            ml: -1,
        },
    };

    return (
        <ListItem
            alignItems="flex-start"
            sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
            }}
            onClick={props.onClick}
        >
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    mr: 2,
                }}
            >
                {props.dateAndTime ? (
                    <>
                        <Box>{formatDate(props.job.scheduledDate).day}</Box>
                        <Box>{formatDate(props.job.scheduledDate).month}</Box>
                    </>
                ) : (
                    <Box>{props.job.time}</Box>
                )}
            </Box>
            <Box
                sx={{
                    backgroundColor: 'white',
                    width: '100%',
                    p: 1,
                    borderRadius: '5px',
                    borderLeftColor: priorityColors[props.job.priority ?? 0],
                    borderLeftWidth: 5,
                    borderLeftStyle: 'solid',
                }}
            >
                <Box>
                    <Box
                        sx={{
                            color: priorityColors[props.job.priority ?? 0],
                            fontWeight: 'bold',
                        }}
                    >
                        {props.job.jobTitle}
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                mr: 2,
                            }}
                        >
                            <LocationOnOutlinedIcon sx={{ mr: 1 }} />
                            {'  '}
                            {props.job.address && props.job.address}
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                mr: 2,
                                textTransform: 'uppercase',
                            }}
                        >
                            <HomeOutlinedIcon sx={{ mr: 1 }} />
                            {props.job.postalCode && props.job.postalCode}
                        </Box>
                        {props.dateAndTime && (
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    mr: 2,
                                }}
                            >
                                <AccessTimeIcon sx={{ mr: 1 }} />
                                {props.job.time && props.job.time}
                            </Box>
                        )}
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            fontStyle: 'italic',
                            color: '#048282',
                        }}
                    >
                        {props.job.tags.map((tag, index) => (
                            <Box key={index} sx={{ mr: 1 }}>{`#${tag}`}</Box>
                        ))}
                    </Box>
                </Box>
                <Box style={styles.avatarContainer}>
                    {props.job.assignedTo &&
                        props.job.assignedTo.map((user) => (
                            <Avatar
                                key={user._id}
                                sx={{
                                    ...styles.avatar,
                                    bgcolor: user.avatarColor,
                                }}
                            >
                                {Array.from(user.name)[0] +
                                    Array.from(user.surname)[0]}
                            </Avatar>
                        ))}
                </Box>
            </Box>
        </ListItem>
    );
};

export default JobListItem;
